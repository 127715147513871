
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Info from './Views/Info';

const App = ()=> {
  return (
    <BrowserRouter>
      <Info />
    </BrowserRouter>
      
  );
}

export default App;
