import React, { memo, useRef, useState, useEffect } from "react";
import { Button, Drawer, Input, Modal, message } from 'antd';
import CustomIcon from "../../common/CustomIcon";
import {
    distributorRedeem,
    //getDistributorTxLog,
} from '../../utils/upNet';
import {
    copyFn,
    convertBalance,
} from "../../utils/common";
//import ModalHistoryTransaction from "../ModalHistoryTransaction";
import { useMemo } from "react";
import "./index.css"

const ModalDistribute = ({
    closeFn = () => {},
    distributor,
}) => {
    const [showEthTips, setShowEthTips] = useState(false);
    const [showTronTips, setShowTronTips] = useState(false);
    const [showHistoryTransaction, setShowHistoryTransaction] = useState(false);

    /*const goTransactionHistory = () => {
        getDistributorTxLog(0).then(res => {
            if (res?.txLog?.length > 0){
                setShowHistoryTransaction(true);
            }
            else{
                setShowHistoryTransaction(true);
                message.error({ content: 'no message'})
            }
        }).catch(e => {
            console.error("[getTxLogError]", e);
        })
    }*/

    const convertWalletAddress = (address) => {
        if (address?.length > 9){
            return `${address.slice(0,5)}...${address.slice(address.length - 4)}`;
        }
        else{
            return address; 
        }
    }

    return (
        <div className={`ModalDistribute flex_center_start_col`}>
            <div className="w100p fs20 fb tlc fontCommon" style={{marginTop: '39px'}}>
                {`Reseller`}
            </div>
            {
                distributor?.wallet?.ethereum?.length > 0 &&
                <div className="w100p flex_center_start_col distributorWalletLine">
                    <div className="w100p flex_center_start">
                        <div className="fs14 fontCommon">
                            {`ERC20-USDT`}
                        </div>
                        <div 
                            className="distributorWalletLineValue ml10"
                            onMouseEnter={() => {
                                if (!showEthTips){
                                    setShowEthTips(true);
                                }
                            }}
                            onMouseLeave={() => {
                                if (showEthTips){
                                    setShowEthTips(false);
                                }
                            }}>
                            <div className="distributorIconRoot">
                                <CustomIcon imgName={`UI_Picture_Ethereum_01`} className="distributorIcon1" width={24} height={24}></CustomIcon>
                                <CustomIcon imgName={`UI_Picture_USDT_01`} className="distributorIcon2" width={24} height={24}></CustomIcon>
                            </div>
                            <div className="fs14 fontCommon">
                                {`${convertBalance(distributor?.cumulativeBalance?.ethereum || 0)} / ${convertBalance(distributor?.balance?.ethereum || 0)}`}
                            </div>
                            {
                                showEthTips &&
                                <div className="distributorWalletLineValueTips">
                                    <span className="fs12 nowrap fontCommon">
                                        {`Total Sales Revenue / Current Balance`}
                                    </span>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="w100p flex_center_start" style={{marginTop: '13px'}}>
                        <CustomIcon imgName={`UI_Picture_Icon_Copy_01`} className="op4" width={24} height={24} onClick={() => {
                            copyFn(distributor?.wallet?.ethereum);
                        }}></CustomIcon>
                        <div className="fs14 color-999 wordBreak fontCommon" style={{marginLeft: '7px'}}>
                            {distributor?.wallet?.ethereum}
                        </div>
                    </div>
                </div>
            }
            {
                distributor?.wallet?.tron?.length > 0 &&
                <div className="w100p flex_center_start_col distributorWalletLine">
                    <div className="w100p flex_center_start">
                        <div className="fs14 fontCommon">
                            {`TRC20-USDT`}
                        </div>
                        <div 
                            className="distributorWalletLineValue ml10"
                            onMouseEnter={() => {
                                if (!showTronTips){
                                    setShowTronTips(true);
                                }
                            }}
                            onMouseLeave={() => {
                                if (showTronTips){
                                    setShowTronTips(false);
                                }
                            }}>
                            <div className="distributorIconRoot">
                                <CustomIcon imgName={`UI_Picture_TRON_01`} className="distributorIcon1" width={24} height={24}></CustomIcon>
                                <CustomIcon imgName={`UI_Picture_USDT_01`} className="distributorIcon2" width={24} height={24}></CustomIcon>
                            </div>
                            <div className="fs14 fontCommon">
                                {`${convertBalance(distributor?.cumulativeBalance?.tron || 0)} / ${convertBalance(distributor?.balance?.tron || 0)}`}
                            </div>
                            {
                                showTronTips &&
                                <div className="distributorWalletLineValueTips">
                                    <span className="fs12 nowrap fontCommon">
                                        {`Total Sales Revenue / Current Balance`}
                                    </span>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="w100p flex_center_start" style={{marginTop: '13px'}}>
                        <CustomIcon imgName={`UI_Picture_Icon_Copy_01`} className="op4" width={24} height={24} onClick={() => {
                            copyFn(distributor?.wallet?.tron);
                        }}></CustomIcon>
                        <div className="fs14 color-999 wordBreak fontCommon" style={{marginLeft: '7px'}}>
                            {distributor?.wallet?.tron}
                        </div>
                    </div>
                </div>
            }
            {/*<div className="w100p flex_center_center" style={{marginTop: '27px'}}>
                <div className={`flex_center_center modalDistributeCommonBtn`} style={{marginRight: '22px'}} onClick={() => { 
                    
                }}>
                    <div className='fs14 pointer fb' >Activation Codes</div>
                </div>
                <div className={`flex_center_center modalDistributeCommonBtn`} onClick={() => { 
                    goTransactionHistory();
                }}>
                    <div className='fs14 pointer fb' >Transaction History</div>
                </div>
            </div>
            <Modal
                width='607px'
                title={''}
                className="confirmModalWrap modalnopadding"
                centered={true}
                open={showHistoryTransaction}
                destroyOnClose={true}
                onOk={() => setShowHistoryTransaction(false)}
                onCancel={() => setShowHistoryTransaction(false)}
            >
                <ModalHistoryTransaction
                    closeFn={() => {
                        setShowHistoryTransaction(false);
                    }}></ModalHistoryTransaction>
            </Modal>*/}
        </div>
    )
}
export default memo(ModalDistribute);
